import CommentsForm from "../components/comments-form";
//import QuickEnquiryForm from "../components/quick-enquiry-form";
//import Scroller from "../util/scroller";
//import NavScroller from "../components/nav-scroller";
//import Swiper from "swiper";

export default {
    init() {
        new CommentsForm();
    },
    finalize() {
    }
};