import Overlay from "../util/overlay";
import Flickity from "flickity";
import isAutoplaySupported from "../util/isAutoplaySupported";

require("flickity/dist/flickity.css");
//import QuickEnquiryForm from "../components/quick-enquiry-form";
//import Scroller from "../util/scroller";
//import Desmusher from "../components/text-desmusher";

// function callback() {
//     this.classList.add("is-visible");
//     var tagline = document.querySelector(".client-grid-tagline");
//         tagline.classList.add("is-visible");
// }

export default {
    init() {
        var overlays = document.querySelectorAll(".overlay");

        if (overlays !== null) {
            for (var i = 0; i < overlays.length; i++) {
                var overlay_id = overlays[i].id;
                var overlay = new Overlay({
                    trigger: document.querySelectorAll(
                        ".overlay__button--" + overlay_id
                    ),
                });
            }
        }

        if (document.querySelector(".home-articles-carousel")) {
            new Flickity(".home-articles-carousel", {
                cellAlign: "left",
                wrapAround: true,
                autoPlay: 4000,
                selectedAttraction: 0.1,
                friction: 1,
                pageDots: false,
            });
        }

        const player = document.getElementById("banner-background-video");
        const isChrome = !!window.chrome && !!window.chrome.webstore;
        if (player !== null) {
            player.addEventListener("playing", function() {
                console.log("playing");
                window.video_playing = true;
            });

            setTimeout(function() {
                isAutoplaySupported((supported) => {
                    if (isChrome || window.video_playing || supported) {
                        console.log("autoplay is supported");
                    } else {
                        console.log("autoplay is not supported, hide video");
                        player.style.display = "none";
                    }
                });
            }, 500);
        }

        // new QuickEnquiryForm();

        // var quickenquirysection = {
        //     element: document.querySelector(".quick-enquiry-section"),
        //     heading: document.querySelector(".quick-enquiry-title"),
        //     text: document.querySelector(".quick-enquiry-subtitle"),
        //     scroll: '',
        //     callback: callback
        // }

        // if (quickenquirysection.element) {
        //     new Desmusher(quickenquirysection.heading, 0);
        //     new Desmusher(quickenquirysection.text, 0);

        //     quickenquirysection.scroll = new Scroller(quickenquirysection.element, {
        //         callback: quickenquirysection.callback,
        //         trigger: "top",
        //         triggerOffset: 0,
        //         triggerOffsetPercentage: 120,
        //         once: true
        //     });
        // }

        // document.querySelector('#view-all-features-btn').addEventListener("click", function(e){
        //     e.preventDefault();

        //     var featureTiles = document.querySelectorAll('.features-row > div');

        //     for (var i = 0; i< featureTiles.length; i++){
        //         if (featureTiles[i].classList.contains('d-none')){
        //             featureTiles[i].style.opacity = 0;
        //             featureTiles[i].classList.remove('d-none');
        //             featureTiles[i].classList.add('d-flex');
        //             $(featureTiles[i]).animate({
        //                 opacity: 1
        //             }, 500);
        //         }
        //     }

        //     this.parentNode.parentNode.removeChild(this.parentNode);
        // });

        // var homePostsSwiper = new Swiper("#home-posts-swiper", {
        //     slidesPerView: 3,
        //     spaceBetween: 30,
        //     centeredSlides: true,
        //     //speed: 1000,
        //     //mousewheel: true,
        //     loop: true,
        //     autoplay: {
        //         delay: 5000,
        //         disableOnInteraction: false,
        //     },
        //     breakpoints: {
        //         768: {
        //             slidesPerView: 2,
        //             spaceBetween: 20,
        //             centeredSlides: false,
        //         },
        //         576: {
        //             slidesPerView: 1,
        //             spaceBetween: 10,
        //             centeredSlides: false,
        //         },
        //     },
        //     pagination: {
        //         el: '.swiper-pagination',
        //         clickable: true,
        //     },
        //     // navigation: {
        //     //     nextEl: '.swiper-button-next',
        //     //     prevEl: '.swiper-button-prev',
        //     // },
        // });
        // window.homePostsSwiper = homePostsSwiper;
    },
    finalize() {},
};
