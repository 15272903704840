// components/category-filter/index

function CategoryFilter() {
    this.filter = document.querySelector(".category-filter");

    if (this.filter) {
        this.label = this.filter.querySelector(".category-filter-label");
        this.list = this.filter.querySelector('.list');
        this.icon = this.filter.querySelector(".share-icon");
      } else {
        return false;
    }

    if (this.filter.nodeType !== 1) {
        return false;
    }

    this.eventListeners();
}

CategoryFilter.prototype.eventListeners = function() {
    this.events = {
        body: this.bodyClick.bind(this)
    };

    this.label.addEventListener("click", this.onClick.bind(this), false);

    this.icon.addEventListener("click", this.onClick.bind(this), false);

    this.filter.addEventListener("click", function (e) {
        e.stopPropagation();
    });
};

CategoryFilter.prototype.bodyClick = function () {
    this.filter.classList.remove("is-open");
    jQuery(this.list).slideUp(200);
};

CategoryFilter.prototype.onClick = function (e) {
    e.preventDefault();
    // var filterLabelLink = document.getElementById('filter-videos-link');
    //     filterLabelLink.textContent = this.textContent;
    this.filter.classList.toggle("is-open");
    jQuery(this.list).slideToggle(200);
    document.addEventListener("click", this.events.body);
};

export default CategoryFilter;