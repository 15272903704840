//import ajax from "@fdaciuk/ajax";
//import 'bootstrap/js/dist/alert';
//import ImgLoad from "../util/imgload";
//import ScrollTo from "../util/scrollto";
//import Scroller from "../util/scroller";
//import Desmusher from "../components/text-desmusher";
//import ElevatorScroller from "../components/elevator-scroller";
import GMenu from "../components/g-menu";
//import Swiper from "swiper"; 
//import Collections from "../components/collections";
//
//import objectFitImages from 'object-fit-images'; // https://github.com/bfred-it/object-fit-images
//import Featherlight from "featherlight";

const isBot = !("onscroll" in window) || /glebot/.test(navigator.userAgent);

// require("modernizr");

export default {
    init() { 

        // // object-fit polyfill
        // if (document.querySelector('.img-cover')){
        //     objectFitImages('img.img-cover');
        // }

        // // object-fit polyfill
        // if (document.querySelector('.img-contain')){
        //     objectFitImages('img.img-contain');
        // }

        new GMenu();
        //new ElevatorScroller();

        // var quickenquirysection = {
        //     element: document.querySelector(".quick-enquiry-section"),
        //     heading: document.querySelector(".quick-enquiry-title"),
        //     text: document.querySelector(".quick-enquiry-subtitle"),
        //     scroll: '',
        //     callback: function () {
        //         this.classList.add("is-visible");
        //     }
        // }

        // var footer = {
        //     element: document.querySelector(".footer"),
        //     scroll: "",
        //     callback: function() {
        //         this.classList.add("is-visible");
        //     },
        // };

        // if ($('#banner-swiper .swiper-slide').length > 1) {
        //     var bannerSwiper = new Swiper("#banner-swiper", {
        //         //slidesPerView: 3,
        //         //spaceBetween: 30,
        //         centeredSlides: true,
        //         speed: 1000,
        //         effect: 'fade',
        //         //mousewheel: true,
        //         loop: true,
        //         autoplay: {
        //             delay: 5000,
        //             disableOnInteraction: false,
        //         },
        //         pagination: {
        //             el: '.swiper-pagination',
        //             clickable: true,
        //         },
        //     });
        //     window.bannerSwiper = bannerSwiper;
        //}

        // if (footer.element) {
        //     footer.scroll = new Scroller(footer.element, {
        //         callback: footer.callback,
        //         trigger: "top",
        //         triggerOffset: 0,
        //         triggerOffsetPercentage: 50,
        //         once: true
        //     });

        // }

        // if (quickenquirysection.element) {
        //     new Desmusher(quickenquirysection.heading, 0);
        //     new Desmusher(quickenquirysection.text, 0);

        //     quickenquirysection.scroll = new Scroller(quickenquirysection.element, {
        //         callback: quickenquirysection.callback,
        //         trigger: "top",
        //         triggerOffset: 0,
        //         triggerOffsetPercentage: 120,
        //         once: true
        //     });
        // }
        
        if (isBot) {
            document.body.classList.add("bot");
        }
    },
    finalize() {
        //var imgload = new ImgLoad(".banner__image");
        //var downArrow = new ScrollTo(".scroll-down");
    }
};
function callback() {
    //this.classList.add("is-visible");
}
