//import 'bootstrap/js/dist/collapse';
//import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/util';

import Router from "./util/Router";

// Routes
import common from "./routes/common";
import home from "./routes/home";
// import pageBlog from "./routes/page-blog";
// import pageAbout from "./routes/page-about";
// import pageCaseStudies from "./routes/page-case-studies";
// import singleCasestudies from "./routes/single-casestudies";
import singlePost from "./routes/single-post";
import pageNearMe from "./routes/page-near-me";
import pageContact from "./routes/page-contact";
import pageJoin from "./routes/page-join";
import pageTemplateOurResources from "./routes/page-resources";
import postTypeArchiveVideos from "./routes/post-type-archive-videos";

const routes = new Router({
    common,
    home,
    // pageBlog,
    // pageAbout,
    // pageCaseStudies,
    // singleCasestudies,
    singlePost,
    pageNearMe,
    pageContact,
    pageJoin,
    pageTemplateOurResources,
    postTypeArchiveVideos,
});

function ready(func) {
    if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
        func();
    } else {
        document.addEventListener("DOMContentLoaded", func);
    }
}

function loadEvents() {
    routes.loadEvents();
}

ready(loadEvents);