/* global google */

import ajax from "@fdaciuk/ajax";
import Map from "../map";
import CustomMarker from "../custom-marker";

function mapFilter(map)
{
  var mapClass = map;
  var activeFilters = [];
  const filtersLength = document.querySelectorAll('.map-filter-link').length;

  
  update_filter_label();
  document.getElementById('map-filter').addEventListener('click', toggle_filters, false);

  var mapFilters = document.querySelectorAll(".map-filter-link");
  if (mapFilters.length > 0) {
    for (var i = 0; i < mapFilters.length; i++) {
        var filterLink = mapFilters[i];

        filterLink.addEventListener("click", function (e) {
          e.preventDefault();
          if (this.dataset.state == "off"){
            var currentState = "off";
            this.dataset.state = "on";
            this.classList.remove('map-filter-off');
          } else {
            var currentState = "on";
            this.dataset.state = "off";
            this.classList.add('map-filter-off');
          }
          //console.log("click map filter: " + this.dataset.posttype);
          // get_locations(this.dataset.type);
          update_filter_label();
          toggle_markers(this.dataset.posttype, currentState);
        });
    }
  }

  function toggle_markers(markerType, currentState)
  {
    //console.log('mapFilter.toggle_markers('+ markerType +')');

    if (currentState === "off") {
      for (var c = 0; c < mapClass.markers.length; c++) {
        if (mapClass.markers[c].args.post_type == markerType) {
          mapClass.markers[c].div.classList.remove("marker-hidden");
        }
      }
    } else if (currentState === "on") {
      for (var c = 0; c < mapClass.markers.length; c++) {
        if (mapClass.markers[c].args.post_type == markerType) {
          mapClass.markers[c].div.classList.add("marker-hidden");
        }
      }
    }

    // for (var c = 0; c < mapClass.markers.length; c++) {
    //   if (mapClass.markers[c].args.type == markerType) { 

    //     // mapClass.markers[c].div.style.display = newDisplayStyle;
        
    //     // visibleMarkerCoords.push({
    //     //   lat: this.mapClass.markers[c].args.lat, 
    //     //   lng: this.mapClass.markers[c].args.lng, 
    //     //   latlng: this.mapClass.markers[c].latlng
    //     // });
    //   } 

      //  else {
      //   this.mapClass.markers[c].div.style.display = "none";
      // }
    //}
  }

  // On initial load, get all regional coordinators
  // get_locations('coordinator');

  function get_locations(locationType)
  {
    //console.log('get_locations('+ locationType +')');

    var filterParams = {
      action   : 'map_filter',
      filter   : locationType,
      //postcode : 'BA12 0QQ',
    }

    ajax().get('/wp/wp-admin/admin-ajax.php', filterParams).then(function (response, xhr) {
      //console.log(response);
      var totalResults = response.results.length;
      if (totalResults > 0){
        for (var m = 0; m < totalResults; m++) {
          console.log(response.results[m]);
        }
        map.addMarkers(response.results);
      }
    });
  }

  // document.querySelectorAll(".map-filter-link").addEventListener("click", function(e){
  //   console.log("click map filter");
  // });
  
  function toggle_filters() {
    var $list = jQuery('#map-filter .map-filter-list');
    $list.slideToggle(200);
    jQuery('#map-filter').toggleClass('open');
  }

  function update_filter_label() {
    var filterTitle = document.querySelector('.map-filter-title');

    activeFilters = document.querySelectorAll('.map-filter-link[data-state="on"]');

    if (activeFilters.length == filtersLength) {
      filterTitle.innerHTML = 'All';
    } else {
      if (activeFilters.length == 1) {
        filterTitle.innerHTML = activeFilters[0].innerHTML;
      } else if (activeFilters.length == 0) {
        filterTitle.innerHTML = filterTitle.dataset.defaultitle;
      } else {
        let label = '';
        activeFilters.forEach(function(el) {
          label += el.innerHTML + ' & ';
        });

        label = label.slice(0, -2);
        filterTitle.innerHTML = label;
      }
    }
  }
}

export default mapFilter;