/* global google */

import Map from "../map";
import camelCase from "../../util/camelCase";

function CustomMarker(latlng, map, args, mapClass) {
    //console.log('CustomMarker');
    this.mapClass = mapClass; // Map
    this.map = map; // Google map
    this.latlng = latlng;
    this.args = args;
    this.setMap(map);
    this.overlay = null;
    //this.overlayIsOpen = false;

    // To reference the marker div you ened to use: this.mapClass.markers[x].div
}

if (typeof google !== "undefined") {
    CustomMarker.prototype = new google.maps.OverlayView();
    CustomMarker.prototype.draw = function() {
        var self = this;
        var div = this.div;
        var topImg = "";

        if (!div) {
            div = this.div = document.createElement("div");
            div.id = "marker" + self.args.pid;
            div.dataset.title = self.args.title;
            div.dataset.itemtype = self.args.item_type;
            div.dataset.typelabel = self.args.type_label;
            div.dataset.posttype = self.args.post_type;
            div.dataset.region = this.args.region;
            div.dataset.pid = self.args.pid;
            div.dataset.latlng = this.latlng;

            // on page load all coordinator markers are shown, others are hidden
            div.className = "marker-div parent" + self.args.parent;

            // div.dataset.address = self.args.address;
            // div.dataset.telephone = self.args.telephone;
            // div.dataset.email = self.args.email;
            // div.dataset.website = self.args.website;

            // default marker colour
            var markerColourNum = 1;

            var hoverLabel = "";
            // on page load all coordinator markers are shown, others are hidden
            if (typeof self.args.post_type !== "undefined") {
                switch (self.args.post_type) {
                    case "coordinator":
                        markerColourNum = 1;
                        div.className = "marker-div";
                        hoverLabel = "Coordinator";
                        if (self.args.region) {
                            hoverLabel += ", " + self.args.region;
                        }
                        break;
                    case "grower":
                        markerColourNum = 2;
                        hoverLabel = "Grower & Supplier";
                        break;
                    case "group":
                        markerColourNum = 3;
                        hoverLabel = "Seed Group";
                        break;
                    case "map_item":
                        markerColourNum = 1;
                        hoverLabel = "Grain";
                        break;
                    default:
                        markerColourNum = 3;
                }
            }

            var markerImg =
                '<img src="/wp-content/themes/seed/dist/images/marker_' +
                markerColourNum +
                '.png" alt="">';

            // if (window.devicePixelRatio > 1) { // 2x
            // }

            if (typeof self.args.marker_img !== "undefined") {
                topImg =
                    '<img class="marker-img" src="' +
                    self.args.marker_img +
                    '" alt="">';
            }

            var buttonText = "View more details";

            if (self.args.post_type == "coordinator") {
                buttonText =
                    `View ` + self.args.title.split(" ")[0] + `\'s Details`;
            }

            var hoverBox =
                `<div class="marker-hover-overlay">
        <h3 class="title h2">` +
                self.args.title +
                `</h3>
        <strong class="posttype h2">` +
                hoverLabel +
                `</strong>
        <a href="#" class="button mt-5 my-xl-auto">` +
                buttonText +
                `</a>
        </div>`;

            // set the marker HTML
            div.innerHTML = markerImg + topImg + hoverBox;

            // custom marker click event
            google.maps.event.addDomListener(div, "click", function(event) {
                //console.log("marker click()");
                //console.log("markerType: " + div.dataset.posttype);

                self.openOverlay();

                /*
        if (self.args.post_type === 'coordinator'){
          // remove/hide other coordinators
          // display associated markers for this coordinator
          //console.log("Coordinator!");
          self.selectCoordinator();
        } else {
          // if there is currently a coordinator selected just open the overlay
          // otherwise focus on this marker
          if (this.mapClass && (this.mapClass.selectedCoordinator === false || this.mapClass.selectedCoordinator <= 0)) {
            self.map.setZoom(10);
            self.map.setCenter(self.getPosition());
          }
        }
        */

                div.classList.add("marker-bigger");

                google.maps.event.trigger(self, "click");

                //self.map.setZoom(10);
                //self.map.setCenter(self.getPosition());

                // self.map.setOptions({
                //   draggable: false, // deprecated
                //   gestureHandling: "none", // recommended
                //   panControl: false, // deprecated
                //   scaleControl: false,
                //   scrollwheel: false, // deprecated
                //   zoomControl: false
                // });
            });

            var panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        var point = this.getProjection().fromLatLngToDivPixel(this.latlng);

        if (point) {
            div.style.left = point.x - 50 + "px";
            div.style.top = point.y - 145 + "px";
        }
    };

    CustomMarker.prototype.remove = function() {
        //console.log('CustomMarker.remove()');
        this.setMap(null);
        if (this.div) {
            this.div.parentNode.removeChild(this.div);
            this.div = null;
        }
    };

    CustomMarker.prototype.getPosition = function() {
        //console.log('CustomMarker.getPosition()');
        return this.latlng;
    };

    /**
     * Select regional coordinator - displays associated markers
     *
     * @param {} markerPostId
     */
    CustomMarker.prototype.selectCoordinator = function() {
        //console.log("CustomMarker.selectCoordinator()");

        this.mapClass.selectedCoordinator = this.args.pid;

        //var childMarkers = document.querySelectorAll(".parent" + this.args.pid);

        // positions of markers that should be visible
        var visibleMarkerCoords = [];

        // include this marker as visible
        visibleMarkerCoords.push({
            lat: this.args.lat,
            lng: this.args.lng,
            latlng: this.args.latlng,
        });

        for (var c = 0; c < this.mapClass.markers.length; c++) {
            if (this.mapClass.markers[c].args.pid != this.args.pid) {
                if (this.mapClass.markers[c].args.parent == this.args.pid) {
                    this.mapClass.markers[c].div.classList.remove(
                        "marker-hidden"
                    );
                    visibleMarkerCoords.push({
                        lat: this.mapClass.markers[c].args.lat,
                        lng: this.mapClass.markers[c].args.lng,
                        latlng: this.mapClass.markers[c].latlng,
                    });
                } else {
                    this.mapClass.markers[c].div.classList.add("marker-hidden");
                }
            }
        }

        this.mapClass.centreMap(visibleMarkerCoords);

        // for (var m = 0; m < this.map.markers.length; m++){
        //   if (this.map.markers[m].pid == markerPostId || this.map.markers[m].parent == markerPostId){
        //     continue;
        //   }
        //   // remove marker div (or maybe should just hide?)
        //   var markerDiv = document.getElementById('marker' + this.map.markers[m].pid);
        //       markerDiv.parentNode.removeChild(markerDiv);
        // }
    };

    /**
     * Close overlay
     */
    CustomMarker.prototype.closeOverlay = function() {
        //console.log('CustomMarker.closeOverlay');

        // show main map search input
        var mapSearchWrapper = document.getElementById("map-search-wrapper");
        mapSearchWrapper.classList.remove("map-search-hidden");
        //mapSearchWrapper.style.display = "block";
        //mapSearchWrapper.classList.remove("map-search-wrapper-open");

        // show map filter
        var mapFilterElement = document.getElementById("map-filter");
        mapFilterElement.classList.remove("map-filter-hidden");

        // allow body to scroll again
        // var body = document.querySelector('body');
        //     body.classList.remove('no-scroll');

        // close this overlay
        var overlay = document.getElementById("overlay" + this.args.pid);
        overlay.classList.remove("map-overlay-open");
        overlay.setAttribute("aria-hidden", true);

        // update the marker DOM element
        for (var m = 0; m < this.mapClass.markers.length; m++) {
            if (this.mapClass.markers[m].args.pid == this.args.pid) {
                this.mapClass.markers[m].div.classList.remove("marker-bigger");
                break;
            }
        }

        if (this.args.post_type == "coordinator") {
            //console.log("unselect coordinator");
            this.mapClass.selectedCoordinator = false;
        }
    };

    /**
     * Open overlay
     */
    CustomMarker.prototype.openOverlay = function() {
        //console.log('CustomMarker.openOverlay');

        if (this.mapClass.openedOverlayPostId > 0) {
            for (var m = 0; m < this.mapClass.markers.length; m++) {
                if (
                    this.mapClass.markers[m].args.pid ==
                    this.mapClass.openedOverlayPostId
                ) {
                    this.mapClass.markers[m].closeOverlay();
                    break;
                }
            }
        }

        // remember that this overlay is currently open
        this.mapClass.openedOverlayPostId = this.args.pid;

        // hide map filter
        var mapFilterElement = document.getElementById("map-filter");
        mapFilterElement.classList.add("map-filter-hidden");

        // stop body from scrolling
        // var body = document.querySelector('body');
        //     body.classList.add('no-scroll');

        // open this overlay
        var overlay = document.getElementById("overlay" + this.args.pid);
        overlay.classList.add("map-overlay-open");
        overlay.scrollTop = 0;
        overlay.setAttribute("aria-hidden", false);

        // hide the main map search input
        var mapSearchWrapper = document.getElementById("map-search-wrapper");
        mapSearchWrapper.classList.add("map-search-hidden");
        // mapSearchWrapper.style.display = "none";
        // mapSearchWrapper.classList.add("map-search-wrapper-open");
    };
}
export default CustomMarker;
