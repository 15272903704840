import ajax from "@fdaciuk/ajax";
import 'bootstrap/js/dist/alert';

function CommentsForm() {
    var commentsForm = document.getElementById("comments");
    if (commentsForm){

        $('input, textarea').each(function(){
            if ($(this).val().length > 0) {
                $(this).addClass("filled");
            } else {
                $(this).removeClass("filled");
            }
        });

        $('input,textarea').on('focus', function(){                                          
            if ($(this).hasClass("is-invalid")){
                $(this).removeClass("is-invalid");
            }
            if ($(this).parent().hasClass("is-invalid")){
                $(this).parent().removeClass("is-invalid");
            }
        });

        $('input,textarea').on('blur', function(){
            if ($(this).val().length > 0) {
                $(this).addClass("filled");
            } else {
                $(this).removeClass("filled");
            }
        });
    }

    function resetForm(){
        // Remove existing feedback messages

        $('input,textarea').each(function(){
            if ($(this).hasClass("is-invalid")){
                $(this).removeClass("is-invalid");
            }
            if ($(this).parent().hasClass("is-invalid")){
                $(this).parent().removeClass("is-invalid");
            }
        });
    }
}

export default CommentsForm;
