import Overlay from "../util/overlay";

export default {
  init() {

    var overlays = document.querySelectorAll('.overlay');

    if (overlays !== null ) {
                
        for (var i = 0; i < overlays.length; i++) {
            var overlay_id = overlays[i].id;
            var overlay = new Overlay({
                trigger: document.querySelectorAll('.overlay__button--'+overlay_id)
            });
        }
    }

    // $('.video-list-link').featherlight();
    // data-featherlight="iframe" data-featherlight-iframe-frameborder="0" data-featherlight-iframe-allow="autoplay; encrypted-media" data-featherlight-iframe-allowfullscreen="true" data-featherlight-iframe-style="display:block;border:none;height:85vh;width:85vw;"
  }
}
