/* global google */

import CustomMarker from "../custom-marker";

function Map(mapElement, mapOptions)
{
  //console.log("Map");

  this.map = null;
  this.markers = [];
  this.infoboxes = [];
  this.openedOverlayPostId = false;
  this.selectedCoordinator = false;

  this.initGoogleMap(mapElement);
}


/**
 * Add markers from HTML tags 
 * @param {array} locations array of location objects
 */
Map.prototype.addMarkers = function(locations)
{
  //console.log('Map.addMarkers()');

  if (locations.length > 0){
    for (var m = 0; m < locations.length; m++){
      this.addMarker(locations[m]);
    }

    this.centreMap();
  }
}

/**
 * Add a marker
 * @param {object} locationObj
 */
Map.prototype.addMarker = function(locationObj)
{
    //console.log('Map.addMarker()');
    
    var latlng = new google.maps.LatLng(locationObj.lat, locationObj.lng);

    // var markerIcon = {
    //   //url: window.devicePixelRatio > 1 ? "/wp-content/themes/seed/dist/images/marker_bg_2x.png" : "/wp-content/themes/seed/dist/images/marker_bg.png",
    //   //url : 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg),
    //   url: "/wp-content/themes/seed/dist/images/marker_bg.png",
    //   size: new google.maps.Size(114, 145),
    //   scaledSize: new google.maps.Size(114, 145),
    //   anchor: new google.maps.Point(57,145)
    // }
    // var marker = new google.maps.Marker({
    //     title    : 'Test',
    //     position : latlng,
    //     map      : this.map,
    //     icon     : markerIcon,
    //     zoom     : 6,
    // });             

    locationObj.position = latlng;

    // create the marker
    var marker = new CustomMarker(latlng, this.map, locationObj, this);

    // store a reference to the marker (on the map object)
    this.markers.push(marker);

    // var bounds = new google.maps.LatLngBounds();
    //     bounds.extend(latlng);
    //     map.fitBounds(bounds);

    // create info window
    // var infowindow = new google.maps.InfoWindow({
    //  content : $marker.html()
    // });

    //// show info window when marker is clicked
    // google.maps.event.addListener(marker, 'click', function() {
    //  infowindow.open(map, marker);
    // });
    //}
}

Map.prototype.centreMap = function(visibleMarkerCoords)
{
  //console.log("Map.centreMap()");

  var bounds = new google.maps.LatLngBounds();

  if (visibleMarkerCoords && visibleMarkerCoords.length > 0) {
    for (var v = 0; v < visibleMarkerCoords.length; v++) {
      var latlng = new google.maps.LatLng(visibleMarkerCoords[v].lat,visibleMarkerCoords[v].lng);
      bounds.extend(latlng);
    }
    this.map.fitBounds(bounds);
    //this.map.setCenter(bounds.getCenter());
    //this.map.setZoom(6);
    if (visibleMarkerCoords.length == 1){
      this.map.setZoom(10);
    }
  } else {

    // only 1 marker?
    if (this.markers.length == 1){
        // set center of map
        this.map.setCenter(bounds.getCenter());
        // map.setZoom(16);
        // this.map.setZoom(this.map.markers[0].zoom);
    } else {
        // fit to bounds
        // this.map.fitBounds(bounds);
        // this.map.setZoom(10);
    }
  }
}


/**
 * Initialise a new map
 * 
 * @param jQuery Object $el Container element for the map
 */
Map.prototype.initGoogleMap = function(mapElement, mapOptionsObj)
{
    //console.log("Map.initGoogleMap()");

    //var desktopScreen = Modernizr.mq( "only screen and (min-width:1024px)" )
    //var scrollable = draggable = !Modernizr.hiddenscroll || desktopScreen;
    
    // var $markers = $el.find('.marker'); // must be before: new google.maps.Map(), because it removes the HTML elements within the map element
    
    var mapOptions = {
        zoom      : 6,
        center    : new google.maps.LatLng(53.4596284, -2.2559642), // Sheffield UK
        mapTypeId : google.maps.MapTypeId.ROADMAP,
        clickableIcons: false, // disable POI info windows
        gestureHandling: 'greedy',
        streetViewControl: false,
        // scrollwheel: scrollable,
        // draggable: draggable,
        // disableDefaultUI: true

        mapTypeControl: false,   
        //mapTypeControlOptions: {
        //    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        // position:google.maps.ControlPosition.RIGHT_CENTER,
            
        //    mapTypeIds: [
        //       google.maps.MapTypeId.ROADMAP,
        //       google.maps.MapTypeId.TERRAIN
        //    ]
        // },
    
        // zoomControl: true,
          zoomControlOptions: {
            style: google.maps.ZoomControlStyle.SMALL,
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
    };
      
    // create map               
    this.map = new google.maps.Map(mapElement, mapOptions);

    // var thisMap = this.map;

    // google.maps.event.addListenerOnce(this.map, 'bounds_changed', function(event) {
    //   console.log('bounds_changed');
    //   console.log(thisMap.getZoom());
    //   if (thisMap.getZoom() < 12) {
    //     thisMap.setZoom(12);
    //   }
    //   console.log(thisMap.getZoom());
    // });

    return this;
}

export default Map;