//import acfGoogleMap from "../components/acf-google-map";
import ajax from "@fdaciuk/ajax";
import "bootstrap/js/dist/alert";

function contactJoinForm() {
    // Enable dismissal of an alert via JavaScript
    $(".alert").alert();

    // Contact Form Ajax
    document
        .getElementById("contact-submit-btn")
        .addEventListener("click", function(e) {
            e.preventDefault();

            var button = this;
            button.classList.add("hidden");

            var spinner = document.getElementById("contact-spinner");
            spinner.classList.add("visible");

            // Remove existing feedback messages
            var contactFormAlert = document.getElementById(
                "contact-form-alert"
            );
            if (contactFormAlert) {
                contactFormAlert.remove();
            }

            var consent_storage = document.getElementById("consent_storage")
                .checked
                ? 1
                : 0;

            var formData = {
                name: document.getElementById("name").value,
                email: document.getElementById("email").value,
                message: document.getElementById("message").value,
                consent_storage: consent_storage,
                // newsletter: newsletter,
                action: "contact_form_submit",
                _wpnonce: document.getElementById("_wpnonce").value,
            };

            if (document.getElementById("contact_number")) {
                formData.contact_number = document.getElementById(
                    "contact_number"
                ).value;
            }

            if (document.getElementById("newsletter")) {
                formData.newsletter = document.getElementById("newsletter")
                    .checked
                    ? 1
                    : 0;
            }

            if (document.getElementById("company")) {
                formData.company = document.getElementById("company").value;
            }

            if (document.getElementById("interested")) {
                formData.interested = document.getElementById(
                    "interested"
                ).value;
            }

            if (document.getElementById("heard")) {
                formData.heard = document.getElementById("heard").value;
            }

            //console.log(formData);
            var request = ajax({
                method: "post",
                url: "/wp/wp-admin/admin-ajax.php",
                data: formData,
            });

            request.then(function(response, xhr) {
                //console.log(response);
                //console.log(xhr);

                spinner.classList.remove("visible");
                button.classList.add("hidden");

                if (response.success === false) {
                    //console.log('FAIL!');
                    //jobsFound.style.display = jobsFoundDisplayStyle;

                    if (response.error) {
                        // Display error message
                        document.getElementById(
                            "contact-alert-wrapper"
                        ).innerHTML =
                            response.error;

                        // Highlight missing fields
                        if (response.missing) {
                            for (var m in response.missing) {
                                var element = document.getElementById(
                                    response.missing[m]
                                );
                                if (!element.classList.contains("is-invalid")) {
                                    element.classList.add("is-invalid");
                                }
                                if (
                                    !element.parentNode.classList.contains(
                                        "is-invalid"
                                    )
                                ) {
                                    element.parentNode.classList.add(
                                        "is-invalid"
                                    );
                                }
                            }
                        }

                        // Highlight invalid fields
                        if (response.invalid) {
                            for (var i in response.invalid) {
                                var element = document.getElementById(
                                    response.invalid[i]
                                );
                                if (!element.classList.contains("is-invalid")) {
                                    element.classList.add("is-invalid");
                                }
                                if (
                                    !element.parentNode.classList.contains(
                                        "is-invalid"
                                    )
                                ) {
                                    element.parentNode.classList.add(
                                        "is-invalid"
                                    );
                                }
                            }
                        }

                        // scroll to top of results
                        $("html, body").animate(
                            {
                                scrollTop:
                                    $("#contact-alert-wrapper").offset().top -
                                    48,
                            },
                            300
                        );
                    }
                } else if (response.success === true) {
                    //console.log('SUCCESS!');
                    if (response.html) {
                        // Display success message
                        document.getElementById(
                            "contact-alert-wrapper"
                        ).innerHTML =
                            response.html;
                    }

                    var formElement = document.getElementById("contact-form");
                    // formElement.remove();
                    formElement.reset();

                    $("input,textarea").removeClass("filled");
                }

                button.classList.remove("hidden");

                // Tell wordpress that parts of the page were reloaded
                document.dispatchEvent(new Event("post-load"));
            });
        });

    $("input, textarea").each(function() {
        var type = $(this).attr("type");
        if (!type || (type !== "hidden" && type !== "checkbox")) {
            if ($(this).val().length > 0) {
                $(this).addClass("filled");
            } else {
                $(this).removeClass("filled");
            }
        }
    });

    $("input,textarea").on("focus", function() {
        if ($(this).hasClass("is-invalid")) {
            $(this).removeClass("is-invalid");
        }

        if (
            $(this)
                .parent()
                .hasClass("is-invalid")
        ) {
            $(this)
                .parent()
                .removeClass("is-invalid");
        }
    });

    $("input,textarea").on("blur", function() {
        var type = $(this).attr("type");
        if (!type || (type !== "hidden" && type !== "checkbox")) {
            if ($(this).val().length > 0) {
                $(this).addClass("filled");
            } else {
                $(this).removeClass("filled");
            }
        }
    });

    // $(document).on('closed.bs.alert', '#contact-form-alert', function(e){
    //     //document.getElementByClassName('form-control').classList.remove('is-invalid');
    //     $('.form-control').removeClass("is-invalid is-valid");
    // });
}

export default contactJoinForm;
