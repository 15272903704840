/* global google */

import Map from "../components/map";
import mapFilter from "../components/map-filter";
import ajax from "@fdaciuk/ajax";

export default {
  init() {

    var body = document.querySelector('body');
        body.classList.add('no-scroll');

    var mapClass; // Map class (map.map is the google map)
    var mapElement = document.getElementById("map");
    var postcodeMarker = null;

    // Parse overlay HTML elements into javascript to create markers
    var locations = [];
    var overlayDivs = document.querySelectorAll(".map-overlay");

    // we only the need the bits of data required to create the marker
    for (var d = 0; d < overlayDivs.length; d++) {
      locations.push({
        pid        : overlayDivs[d].dataset.pid,
        parent     : overlayDivs[d].dataset.parent,
        title      : overlayDivs[d].dataset.title,
        lat        : overlayDivs[d].dataset.lat,
        lng        : overlayDivs[d].dataset.lng,
        marker_img : overlayDivs[d].dataset.markerimg,
        post_type  : overlayDivs[d].dataset.posttype,
        item_type  : overlayDivs[d].dataset.itemtype,
        type_label : overlayDivs[d].dataset.typelabel,
        region     : overlayDivs[d].dataset.region
      });
    }

    if (window.isMapInit === true) {    
        setupMap();    
    } else {
        document.addEventListener("map-init", function () {
          setupMap();    
        });
    }

    function setupMap()
    {
      mapClass = new Map(mapElement);
      new mapFilter(mapClass);
      // map.addMarkers(coordinators);
      mapClass.addMarkers(locations);

      // close overlay
      document.addEventListener('click', function(e){
        if (e.target){
          var target = e.target;

          if (target.matches("a.map-overlay-close-link") || target.matches('.map-search-button')){ //  || !target.matches("img.marker-img")
            if (mapClass.openedOverlayPostId > 0){
                e.preventDefault();
                e.stopPropagation();

                for (var m = 0; m < mapClass.markers.length; m++) {
                  if (mapClass.markers[m].args.pid == mapClass.openedOverlayPostId) {
                    mapClass.markers[m].closeOverlay();
                    break;
                  }
                }
              }
          }
        }
      });

      $(document).on('click', ".map-search-button", function(e){
        e.preventDefault();

        // remove current postcode marker
        if (postcodeMarker !== null) {
          postcodeMarker.setMap(null);
        }

        var postcode = $(e.target).closest('.row').find(".map-input").val();

        if (postcode == "") {
          return;
        }

        // set postcode in all overlay map inputs
        $('.map-input').val(postcode);
        
        // get lat/lng for this postcode
        var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: postcode}, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                
                // centre the map
                mapClass.map.setCenter(results[0].geometry.location);
                
                // create a marker for this postcode
                var marker = new google.maps.Marker({
                    map: mapClass.map,
                    position: results[0].geometry.location,
                });

                // create an info window to display the postcode
                var infowindow = new google.maps.InfoWindow({
                  content: '<div class="p-1">'+
                           '<h6 class="m-0">' + postcode.toUpperCase() + '</h6>'+
                           '</div>'
                });       

                // display the info window when the marker is clicked
                marker.addListener('click', function() {
                  infowindow.open(mapClass.map, marker);
                });        

                // zoom the map in on the postcode marker
                mapClass.map.setZoom(9);

                // store a reference to this postcode marker
                postcodeMarker = marker;
              }
              else {
                postcodeMarker = null;
                // console.log('Geocode was not successful for the following reason: ' + status);
              }
            });
      });
    }

    // Remove the "scroll to top" elevator
    // it's not needed on this page as the whole page is a fixed height
    var elevator = document.getElementById('elevator');
        elevator.parentNode.removeChild(elevator);
  }
}
